<template>
  <div>
    <a-row :gutter="[8,16]">
      <a-col>
        <div class="simiot-card-box">
          <!-- 操作详情字段 -->
          <allocation-record-sim-cards-import-info
            :allocation-record-info="allocationRecordInfo"
          />
        </div>
      </a-col>
    </a-row>

    <a-row :gutter="[8,16]">
      <a-col>
        <div class="simiot-card-box">
          <!-- 操作详情列表搜索 -->
          <batch-operation-record-common-details-search @submit="submitSearch" />

          <div class="simiot-table-operation-buttons">
            <a-space>
              <a-button
                type="primary"
                @click="showReAllocateModal"
                v-if="isShowReAllocateMenu()"
              >重新划拨</a-button>
              <a-button type="primary" @click="exportData">导出</a-button>
            </a-space>
          </div>

          <!-- 操作详情列表 -->
          <batch-operation-record-common-details-list
            :data="tableData"
            :loading="tableLoading"
          />

          <pagination
            v-show="pagination.total_count > 0"
            :total-count="pagination.total_count"
            :page.sync="query.page"
            :per-page.sync="query.per_page"
            @change="fetchData"
          />

          <!-- 重新划拨 -->
          <batch-operation-record-re-allocate-to-top-agent
            v-if="isShowReAllocateModal"
            :visible.sync="isShowReAllocateModal"
            :batch-operation-record-id="allocationRecordInfo.id"
            @completed="fetchData"
          />
        </div>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import AllocationRecordSimCardsImportInfo from '@/views/allocation_records/detail/Info'
import { findAllocationRecord, findAllocationDetails, exportAllocationDetails } from '@/api/allocation_record.js'
import Pagination from '@/components/Pagination'
import BatchOperationRecordCommonDetailsSearch from '@/views/batch_operation_records/detail/CommonDetailsSearch'
import BatchOperationRecordCommonDetailsList from '@/views/batch_operation_records/detail/CommonDetailsList'
import { hasPermission } from '@/utils/permission'
import { exportExcel } from '@/api/excel'

export default {
  name: 'ShowAllocationRecord',
  components: {
    BatchOperationRecordReAllocateToTopAgent: () => import('@/views/batch_operation_records/detail/allocate_cards_to_top_agent/ReAllocateToTopAgent'),
    AllocationRecordSimCardsImportInfo,
    BatchOperationRecordCommonDetailsSearch,
    BatchOperationRecordCommonDetailsList,
    Pagination
  },
  data() {
    return {
      allocationRecordInfo: {},
      tableLoading: true,
      tableData: [],
      isShowReAllocateModal: false,
      pagination: {
        total_count: 0
      },
      query: {}
    }
  },
  computed: {
    allocationRecordId() {
      return parseInt(this.$route.params.id)
    },
    isOperationFailed() {
      return this.allocationRecordInfo.failure_count > 0
    }
  },
  created() {
    this.fetchAllocationRecord()
    this.fetchData()
  },
  methods: {
    isShowReAllocateMenu() {
      return this.isOperationFailed && hasPermission('agent_purchase_items/allocate')
    },

    showReAllocateModal() {
      this.isShowReAllocateModal = true
    },

    submitSearch(search) {
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        { per_page: this.query.per_page },
        search)
      this.fetchData()
    },

    fetchAllocationRecord() {
      findAllocationRecord(this.allocationRecordId).then((res) => {
        if (res.code === 0) {
          this.allocationRecordInfo = res.data
        }
      })
    },

    fetchData() {
      this.tableLoading = true
      findAllocationDetails(Object.assign({}, this.query, { allocation_operation_record_id: this.allocationRecordId })).then((res) => {
        if (res.code === 0) {
          this.tableData = res.data
          this.pagination = res.pagination
        }
        this.tableLoading = false
      })
    },

    exportData() {
      exportAllocationDetails(Object.assign({ allocation_operation_record_id: this.allocationRecordId }, this.query)).then((res) => {
        if (res.code === 0 && res.data.is_direct_download) {
          exportExcel(res.data.id, res.data.file_name)
        }
      })
    }
  }
}
</script>
