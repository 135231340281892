import request from '@/utils/request'

// 查询划拨列表
export function findAllocationRecords(params) {
  return request({
    url: `/allocation_records`,
    method: 'get',
    params: params
  })
}

// 查询划拨详情
export function findAllocationRecord(id) {
  return request({
    url: `/allocation_records/${id}`,
    method: 'get'
  })
}

// 查询划拨卡详情
export function findAllocationDetails(params) {
  return request({
    url: `/allocation_records/detail`,
    method: 'get',
    params
  })
}

// 导出划拨列表
export function exportAllocationRecords(data) {
  return request({
    url: `/allocation_records/export`,
    method: 'post',
    data
  })
}

// 导出划拨卡详情
export function exportAllocationDetails(params) {
  return request({
    url: `/allocation_records/detail/export`,
    method: 'post',
    params
  })
}
