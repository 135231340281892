<template>
  <div>
    <a-row>
      <a-col :span="24">
        <a-descriptions
          :column="3"
          class="simiot-descriptions simiot-descriptions-max-6"
        >
          <a-descriptions-item label="批量操作类型">
            {{ allocationRecordInfo.operation_type }}
          </a-descriptions-item>
          <a-descriptions-item label="订单编号">
            {{ allocationRecordInfo.order_no }}
          </a-descriptions-item>
          <a-descriptions-item label="项目编号">
            {{ allocationRecordInfo.item_no }}
          </a-descriptions-item>

          <a-descriptions-item label="操作批次">
            {{ allocationRecordInfo.operation_no }}
          </a-descriptions-item>
          <a-descriptions-item label="划拨对象">
            {{ allocationRecordInfo.allocation_agent_name }}
          </a-descriptions-item>
          <a-descriptions-item label="划拨总数">
            {{ allocationRecordInfo.operation_count }}
          </a-descriptions-item>

          <a-descriptions-item label="操作时间">
            {{ allocationRecordInfo.created_at }}
          </a-descriptions-item>
          <a-descriptions-item label="操作人">
            {{ allocationRecordInfo.operator_name }}
          </a-descriptions-item>
          <a-descriptions-item label="划拨成功数">
            {{ allocationRecordInfo.success_count }}
          </a-descriptions-item>

          <a-descriptions-item label="重跑次数">
            {{ allocationRecordInfo.run_times }}
          </a-descriptions-item>
          <a-descriptions-item label="划拨失败数">
            {{ allocationRecordInfo.failure_count }}
          </a-descriptions-item>
        </a-descriptions>
      </a-col>
    </a-row>

    <a-row>
      <a-col>
        <a-descriptions
          class="simiot-descriptions"
        >
          <a-descriptions-item label="备注">
            {{ allocationRecordInfo.remark }}
          </a-descriptions-item>
        </a-descriptions>
      </a-col>
    </a-row>

    <a-row>
      <a-col>
        <span>
          注：执行卡号划拨作时，如果有一张卡划拨失败，不会影响其他卡的划拨，但本次划拨的操作结果为失败。
        </span>
      </a-col>
    </a-row>
  </div>
</template>

<script>
export default {
  name: 'AllocationRecordInfo',
  props: {
    allocationRecordInfo: {
      type: Object,
      required: true
    }
  }
}
</script>
